// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import PageAudit from './PageAudit';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Firebase imports
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAuJsJtHJmQgV3730hIsqf0282ezvDpLFw",
  authDomain: "page-indexing-checker.firebaseapp.com",
  projectId: "page-indexing-checker",
  storageBucket: "page-indexing-checker.appspot.com",
  messagingSenderId: "933837748530",
  appId: "1:933837748530:web:c08daec93f7edb6cbbff2b",
  measurementId: "G-RE55L85JYX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
if (typeof window !== 'undefined') {
  const analytics = getAnalytics(app);
  console.log('Firebase Analytics Initialized');
} else {
  console.log('Firebase Analytics not initialized due to being on a non-browser environment');
}

ReactDOM.render(
  <BrowserRouter> {/* Use BrowserRouter here */}
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="/audit" element={<PageAudit />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();