// src/AuditFAQSection.js
import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Helmet from 'react-helmet';

const AuditFAQSection = () => {
    const faqs = [
        {
            question: "1. What does a page audit include?",
            answer: "A page audit typically includes checks for SEO best practices, such as canonical URLs, presence of meta tags, and proper use of heading tags."
        },
        {
            question: "2. How can I improve my website's SEO score?",
            answer: "Improving your SEO score can be achieved by optimizing meta tags, ensuring fast load times, using relevant keywords, and creating quality content."
        },
        {
            question: "3. Why is it important to regularly audit web pages?",
            answer: "Regular audits help identify SEO weaknesses, stay aligned with the latest search engine algorithms, and ensure the best possible user experience."
        },
        {
            question: "4. Can I audit pages not indexed by search engines?",
            answer: "Yes, you can audit any accessible webpage, but improving its SEO can help it get indexed."
        },
        {
            question: "5. How often should I audit my website?",
            answer: "It's recommended to conduct website audits periodically, such as quarterly or bi-annually, and whenever major updates are made to your site."
        }
        // Add more FAQs here if needed
    ];

    const faqStructuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(faqStructuredData)}
                </script>
            </Helmet>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default AuditFAQSection;
