// src/FAQSection.js
import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Helmet from 'react-helmet';

const FAQSection = () => {
    const faqs = [
        {
            question: "1. What is page indexing?",
            answer: "Page indexing refers to the process of a search engine (like Google) adding web pages to its index. These indexed pages are then used in search results. If a page is not indexed, it will not appear in search engine results."
        },
        {
            question: "2. Why is page indexing important?",
            answer: "Page indexing is crucial because it makes your website's content discoverable by search engines. If your pages are indexed, they can appear in search results, which is vital for attracting organic traffic and improving your site's visibility and credibility."
        },
        {
            question: "3. How to improve my page indexing?",
            answer: "To improve page indexing, ensure your website is well-structured and has a sitemap, submit your URL to search engines, improve your site's loading speed, use relevant keywords, and regularly update your site with quality content."
        },
        {
            question: "4. How do I use this tool?",
            answer: "You can use this tool by following the steps provided in the application. Start by inputting your sitemap URL, then upload your indexed URL CSV, and finally, find out the not indexed URLs."
        },
        {
            question: "5. Is this service free?",
            answer: "Yes, this tool is completely free to use."
        },
        {
            question: "6. How to get your sitemap URL?",
            answer: "You can usually find your sitemap URL by appending '/sitemap.xml' to your website's base URL."
        },
        {
            question: "7. How to download the indexed URL csv at search console?",
            answer: "Go to Google Search Console (https://search.google.com/search-console/index/drilldown?pages=ALL_URLS), select your property, click on 'View data about indexed pages', click 'EXPORT', click 'Download CSV'. Upload the 'Table.csv' in the downloaded folder."
        },
        {
            question: "8. How to request indexing at search console?",
            answer: "In Google Search Console, use the URL Inspection tool to submit individual URLs for indexing."
        },
        
    ];

    const faqStructuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(faqStructuredData)}
                </script>
            </Helmet>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default FAQSection;