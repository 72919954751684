// src/PageAudit.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Stack, Typography, Box, Button } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './logo192.png';
import { BASE_URL } from './config';
import AuditFAQSection from './AuditFAQSection';
import { CircularProgress } from '@mui/material';

const PageAudit = () => {
    const [auditUrl, setAuditUrl] = useState('');
    const [auditResults, setAuditResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleAuditUrlChange = (event) => {
        setAuditUrl(event.target.value);
    };

    const handleAudit = async () => {
        setIsLoading(true);
        let processedUrl = auditUrl;
        
        if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
            processedUrl = `https://${processedUrl}`;
        }
    
        try {
            const encodedUrl = encodeURIComponent(processedUrl);
            console.log('Encoded URL:', encodedUrl);
    
            const fetchUrl = `${BASE_URL}/api/audit?url=${encodedUrl}`;
            console.log('Fetch URL:', fetchUrl);
    
            const response = await fetch(fetchUrl);
            
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            // Mocking the audit logic for demonstration
            const results = [
                { test: "Canonical URL", status: data.canonical ? "pass" : "fail" },
                { test: "No Index Tag", status: !data.noIndex ? "pass" : "fail" },
                { test: "Meta Title", status: data.metaTitle ? "pass" : "fail" },
                { test: "Meta Description", status: data.metaDescription ? "pass" : "fail" },
                { test: "H1 Tag", status: data.h1Tag ? "pass" : "fail" },
            ];
    
            setAuditResults(results);
        } catch (error) {
            console.error('Error during audit:', error);
            setAuditResults([]);
        }
        setIsLoading(false);
    };
    
    return (
        <Container style={{ padding: '20px', marginTop: '20px', marginBottom: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
            <Helmet>
                <title>Page Audit - Analyze Your Webpages</title>
                <link rel="canonical" href="http://seo.schooldays.xyz/audit" />
            </Helmet>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                    <Stack direction="column" justifyContent="flex-start" spacing={0.5}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Page Auditing
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: '#666' }}>
                            Analyze Your Webpages for SEO Best Practices
                        </Typography>
                    </Stack>
                </Box>
                <Button
                    variant="contained"
                    component={Link}
                    to="/"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                        color: 'white',
                        '&:hover': {
                            boxShadow: '0 4px 7px 3px rgba(255, 105, 135, .3)',
                            backgroundImage: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
                        },
                    }}
                >
                    Page Indexing
                </Button>
            </Box>
            <Typography variant="body1" style={{ margin: '10px 0' }}>
                Enter the URL of the page you want to audit:
            </Typography>
            <TextField
                label="Page URL"
                variant="outlined"
                fullWidth
                value={auditUrl}
                onChange={handleAuditUrlChange}
                margin="normal"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAudit}
                sx={{ marginTop: '10px' }}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} /> : 'Audit'}
            </Button>
            {auditResults.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Results</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auditResults.map((result, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {result.test}
                                    </TableCell>
                                    <TableCell align="center">
                                        {result.status === "pass" ? (
                                            <span style={{ color: 'green' }}>✔</span>
                                        ) : (
                                            <span style={{ color: 'red' }}>✖</span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <AuditFAQSection />
            <Box sx={{ width: '100%', textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                <Typography variant="body2" color="textSecondary">
                    Made by <a href="https://schooldays.xyz" target="_blank" rel="noopener noreferrer">schooldays.xyz</a> v1.2
                </Typography>
            </Box>
        </Container>
    );
};

export default PageAudit;
