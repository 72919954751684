// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet'; //
import { Container, TextField, Stack, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stepper, Step, StepLabel, Checkbox, Typography, IconButton, Snackbar, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import axios from 'axios';
import Papa from 'papaparse';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import logo from './logo192.png';
import { BASE_URL } from './config';
import { CircularProgress } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from 'react-router-dom';
import FAQSection from './FAQSection';

function App() {
    const [activeStep, setActiveStep] = useState(0);
    const [sitemapUrl, setSitemapUrl] = useState(localStorage.getItem('sitemapUrl') || '');
    const [indexedUrls, setIndexedUrls] = useState([]);
    const [unindexedUrls, setUnindexedUrls] = useState([]);
    const [requestedUrls, setRequestedUrls] = useState(JSON.parse(localStorage.getItem('requestedUrls') || '{}'));
    const [copySuccess, setCopySuccess] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [faqOpen, setFaqOpen] = useState(false);
    const [currentFaq, setCurrentFaq] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      localStorage.setItem('requestedUrls', JSON.stringify(requestedUrls));
      localStorage.setItem('sitemapUrl', sitemapUrl);
      if (indexedUrls.length > 0) {
          localStorage.setItem('indexedUrls', JSON.stringify(indexedUrls));
      }
  }, [requestedUrls, sitemapUrl, indexedUrls]);

    // On component mount, try to retrieve indexed URLs from local storage
    useEffect(() => {
      const storedIndexedUrls = JSON.parse(localStorage.getItem('indexedUrls') || '[]');
      if (storedIndexedUrls.length > 0) {
          setIndexedUrls(storedIndexedUrls);
          setUploadedFileName('Previously uploaded CSV. Click to upload a new one.');
      }
  }, []);

    const handleFileChange = (event) => {
      if (event.target.files && event.target.files[0]) {
          processFile(event.target.files[0]);
      }
  };

  const fileInputRef = useRef(null);

  const handleClickBox = () => {
      fileInputRef.current.click();
  };

  const handleFileDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
          processFile(event.dataTransfer.files[0]);
      }
  };

    const processFile = (file) => {
        setUploadedFileName(file.name);
        Papa.parse(file, {
            complete: (results) => {
                if (results.errors.length > 0) {
                    console.error('Errors parsing CSV:', results.errors);
                    return;
                }

                const indexed = results.data
                    .filter(row => row.URL && row.URL.trim())
                    .map(row => row.URL.trim());

                setIndexedUrls(indexed);
            },
            header: true,
            skipEmptyLines: true
        });
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleSitemapChange = (event) => {
        setSitemapUrl(event.target.value);
    };

    const compareUrls = async () => {
        setIsLoading(true);
        // Updated URL to point to your deployed Firebase Function
        const serverUrl = `${BASE_URL}/api/sitemap`;
        try {
            const response = await axios.get(`${serverUrl}?url=${encodeURIComponent(sitemapUrl)}`);
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(response.data, 'text/xml');
            const urls = Array.from(xmlDoc.getElementsByTagName('loc')).map(node => node.textContent.trim());
    
            const unindexed = urls.filter(url => !indexedUrls.includes(url));
            setUnindexedUrls(unindexed);
            setActiveStep(2); // Move to Step 3 after comparison
        } catch (error) {
            console.error('Full error:', error);
        }
        setIsLoading(false);
    };    

    const handleNext = () => {
        setIsLoading(true);
        if (activeStep === 0) {
            setActiveStep(1);
            setIsLoading(false);
        } else if (activeStep === 1) {
            compareUrls();
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        setCopySuccess(true);
    };

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setCopySuccess(false);
  };

    const handleCheckboxChange = (url) => {
        setRequestedUrls(prevState => ({
            ...prevState,
            [url]: !prevState[url]
        }));
    };

    const handleFaqOpen = (faqText) => {
      setCurrentFaq(faqText);
      setFaqOpen(true);
  };

  const handleFaqClose = () => {
      setFaqOpen(false);
  };

  const faqContent = (step) => {
      switch (step) {
          case 0:
              return "How to get your sitemap URL? You can usually find your sitemap URL by appending '/sitemap.xml' to your website's base URL.";
          case 1:
              return "How to download the indexed URL csv at search console? Go to Google Search Console (https://search.google.com/search-console/index/drilldown?pages=ALL_URLS), select your property, click on 'View data about indexed pages', click 'EXPORT', click 'Download CSV'. Upload the 'Table.csv' in the downloaded fodler.";
          case 2:
              return "How to request indexing at search console? In Google Search Console, use the URL Inspection tool to submit individual URLs for indexing.";
          default:
              return '';
      }
  };

    const stepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography variant="body1">
                            Input your Sitemap URL below:
                            <IconButton onClick={() => handleFaqOpen(faqContent(activeStep))}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Typography>
                        <TextField
                            label="Your Sitemap URL"
                            variant="outlined"
                            fullWidth
                            value={sitemapUrl}
                            onChange={handleSitemapChange}
                            margin="normal"
                        />
                    </>
                );
                case 1:
                  return (
                      <>
                        <Typography variant="body1">
                            Upload your Table.csv below:
                            <IconButton onClick={() => handleFaqOpen(faqContent(activeStep))}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Typography>
                          <Box 
                              onClick={handleClickBox}
                              onDrop={handleFileDrop} 
                              onDragOver={handleDragOver} 
                              border={1} 
                              borderColor="grey.400" 
                              borderRadius="borderRadius" 
                              p={2} 
                              textAlign="center" 
                              style={{ cursor: 'pointer', backgroundColor: '#f0f0f0', position: 'relative' }}
                          >
                              {uploadedFileName || "Drag and drop a CSV file here or click to upload"}
                              <input
                                  ref={fileInputRef}
                                  type="file"
                                  onChange={handleFileChange}
                                  style={{ display: 'none' }}
                              />
                          </Box>
                      </>
                  );
            case 2:
                return (
                    <>
                        <Typography variant="body1">
                            Not indexed pages are listed below:
                            <IconButton onClick={() => handleFaqOpen(faqContent(activeStep))}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Typography>
                    </>
                );
            default:
                return 'Unknown Step';
        }
    };

    return (
        <Container style={{ padding: '20px', marginTop: '20px', marginBottom: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
          <Helmet>
            <title>Page Indexing Checker - Request Page Indexing in Bulk for Not Indexed Pages</title>
            <link rel="canonical" href="http://seo.schooldays.xyz" />
          </Helmet>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
              <Stack direction="column" justifyContent="flex-start" spacing={0.5}>
                <Typography variant="h4" component="h1" gutterBottom>
                  Page Indexing Checker
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#666' }}>
                  Request Page Indexing in Bulk for Not Indexed Pages
                </Typography>
              </Stack>
            </Box>
            <Button
              variant="contained"
              component={Link}
              to="/audit"
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                color: 'white',
                '&:hover': {
                  boxShadow: '0 4px 7px 3px rgba(255, 105, 135, .3)',
                  backgroundImage: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
                },
              }}
            >
              Page Auditing
            </Button>
          </Box>
            <Typography variant="body1" style={{ margin: '10px 0' }}>
                This is a free tool to help you to keep your website up-to-date in Google search results.
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                    <StepLabel>Input Sitemap URL</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Upload Indexed URL CSV</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Find Out Not Indexed URLs</StepLabel>
                </Step>
            </Stepper>
            

            <div style={{ marginTop: '20px' }}>
                {stepContent(activeStep)}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                {activeStep < 2 && (
                    <Button variant="contained" color="primary" onClick={handleNext} disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Next'}
                    </Button>
                )}
            </div>

            {activeStep === 2 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Not Indexed Pages ({unindexedUrls.length})</TableCell>
                                <TableCell>Copy</TableCell>
                                <TableCell>Requested</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unindexedUrls.map((url, index) => (
                                <TableRow key={index}>
                                    <TableCell>{url}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => copyToClipboard(url)} size="small">
                                            <FileCopyIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            checked={requestedUrls[url] || false}
                                            onChange={() => handleCheckboxChange(url)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Snackbar
                open={copySuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message="URL copied to clipboard"
            />
            <Dialog open={faqOpen} onClose={handleFaqClose}>
                <DialogTitle>FAQ</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {currentFaq}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFaqClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FAQSection />
            <Box sx={{ width: '100%', textAlign: 'center', padding: '10px', marginTop: '10px' }}>
            <Typography variant="body2" color="textSecondary">
                Made by <a href="https://schooldays.xyz" target="_blank" rel="noopener noreferrer">schooldays.xyz</a> v1.2
            </Typography>
        </Box>
        </Container>
    );
}

export default App;
